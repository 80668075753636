<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-function-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "BaseModelFields.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                v-model="setCodeFunctionData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "BaseModelFields.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>

              <input
                type="text"
                class="form-control text-uppercase"
                id="FormulaName"
                :value="setCodeFunctionData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
                disabled
              />
            </div>
            <div class="col-md-12">
              <label for="Description" class="form-label required"
                >{{
                  $t(
                    "BaseModelFields.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <textarea
                class="form-control"
                id="Description"
                v-model="setCodeFunctionData.description"
                :spellcheck="this.$isTextSpellCheck"
                required
              ></textarea>
            </div>
            <div class="col-md-12">
              <div class="card mt-2">
                <div class="card-body p-3">
                  <h3 class="card-title">
                    <i class="bi bi-sliders"></i>
                    {{
                      $t(
                        "SetCodeFunctions.FunctionDefinition",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </h3>
                  <div class="function-definition">
                    <div class="row">
                      <div class="col col-auto function-output pe-1">
                        <template
                          v-if="!setCodeFunctionData.functionOutputModel"
                        >
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#modalSetFunctionChangeModel"
                            @click="changed(null, 1)"
                          >
                            <span
                              class="text-danger"
                              v-text="outputModelUndefinedText"
                            ></span>
                          </a>
                        </template>
                        <template v-else
                          ><a
                            data-bs-toggle="modal"
                            data-bs-target="#modalSetFunctionSelectedModel"
                            class="function-definition-link"
                            @click="
                              selected(
                                setCodeFunctionData.functionOutputModel,
                                1
                              )
                            "
                            >{{
                              setCodeFunctionData.functionOutputModel
                                .formulaName
                            }}</a
                          >
                          <button
                            type="button"
                            class="btn btn-danger btn-xs ms-2"
                            @click="clearSelection(1)"
                          >
                            <i class="bi bi-x"></i>
                            {{
                              $t(
                                "SetCodeFunctions.ClearSelection",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </button></template
                        >
                      </div>
                      <div class="col col-auto function-name pe-0">
                        <template
                          v-if="
                            String.isNullOrWhiteSpace(setCodeFunctionData.name)
                          "
                        >
                          FunctionName
                        </template>
                        <template v-else>
                          <span class="text-capitalize">
                            {{ setCodeFunctionData.formulaName }}
                          </span>
                        </template>
                      </div>
                      <div class="col col-auto text-white pe-0 ps-0">(</div>
                      <div class="col col-auto function-input pe-0 ps-0">
                        <template
                          v-if="!setCodeFunctionData.functionInputModel"
                        >
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#modalSetFunctionChangeModel"
                            @click="changed(null, 2)"
                          >
                            <span
                              class="text-danger"
                              v-text="inputModelUndefinedText"
                            ></span>
                            {{
                              $t(
                                "SetCodeFunctions.InputModel",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </a>
                        </template>
                        <template v-else>
                          <a
                            class="function-definition-link"
                            data-bs-toggle="modal"
                            data-bs-target="#modalSetFunctionSelectedModel"
                            @click="
                              selected(
                                setCodeFunctionData.functionInputModel,
                                2
                              )
                            "
                            >{{
                              setCodeFunctionData.functionInputModel.formulaName
                            }}
                          </a>
                          {{
                            $t(
                              "SetCodeFunctions.InputModel",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                          <button
                            type="button"
                            class="btn btn-danger btn-xs ms-2"
                            @click="clearSelection(2)"
                          >
                            <i class="bi bi-x"></i>
                            {{
                              $t(
                                "SetCodeFunctions.ClearSelection",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </button></template
                        >
                      </div>
                      <div class="col col-auto text-white ps-0">)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label for="FunctionCode" class="form-label required"
                >{{
                  $t(
                    "SetCodeFunctions.FunctionCode",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <v-ace-editor
                v-model:value="setCodeFunctionData.functionCode"
                lang="vbscript"
                theme="monokai"
                @init="editorInit"
                :options="options"
                style="height: 200px"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="setCodeFunctionData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              @clear="onClear()"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
  <SetCodeFunctionModelChangeModal
    :refresh="changedObj.refresh"
    :changedModelType="changedObj.type"
    :model="changedObj.model"
    @onModelChanged="modelChangeEmitter"
    @onSelectedModel="onSelectedModel"
  />
  <SetCodeFunctionSelectedModelModal
    ref="modalSetFunctionSelectedModel"
    :refresh="selectedObj.refresh"
    :selectedModelType="selectedObj.type"
    :selectedModel="selectedObj.model"
    @onModelSelected="onModelSelected"
  />
</template>
<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-vbscript";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/vbscript";
import "ace-builds/src-noconflict/ext-searchbox";

import SetCodeFunctionModelChangeModal from "@/components/custom/setcode/function/ModelChangeModal.vue";
import SetCodeFunctionSelectedModelModal from "@/components/custom/setcode/function/ModelSelectedModal.vue";
import $ from "jquery";
export default {
  name: "SetCodeFunctionEdit",
  components: {
    SetCodeFunctionModelChangeModal,
    SetCodeFunctionSelectedModelModal,
    VAceEditor,
  },
  data() {
    return {
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      outputModelUndefinedText: this.$t(
        "SetCodeFunctions.OutputModelText",
        {},
        { locale: this.$store.state.activeLang }
      ),
      inputModelUndefinedText: this.$t(
        "SetCodeFunctions.InputModelText",
        {},
        { locale: this.$store.state.activeLang }
      ),
      selectedObj: {},
      changedObj: {},
      disabledButtonClasses: ".btn-save, .btn-clear",
      errors: [],
      setCodeFunctionData: {
        isActive: true,
        functionInputModel: null,
        functionOutputModel: null,
      },
      isSuccess: false,
    };
  },
  methods: {
    onModelSelected(setModelData, modelType) {
      var modelCode = setModelData.modelCode || "";
      if (modelType == 1) {
        this.setCodeFunctionData.functionOutputModel = {
          formulaName: setModelData.formulaName,
          name: setModelData.name,
          modelCode: modelCode,
          description: setModelData.description,
          isActive: setModelData.isActive,
          publicId: setModelData.publicId,
        };
      } else {
        this.setCodeFunctionData.functionInputModel = {
          formulaName: setModelData.formulaName,
          name: setModelData.name,
          modelCode: modelCode,
          description: setModelData.description,
          isActive: setModelData.isActive,
          publicId: setModelData.publicId,
        };
      }
    },
    getFunction() {
      this.$prodGatewayAxios
        .get(`/Brs-SetCodeFunctionGet?id=${this.$route.params.functionId}`)
        .then((response) => {
          const result = response.data;
          this.setCodeFunctionData = { ...result };
        })
        .catch(function (error) {
          alert(error);
        });
    },
    clearSelection(modelType) {
      if (modelType == 1) {
        this.setCodeFunctionData.functionOutputModel = null;
      } else {
        this.setCodeFunctionData.functionInputModel = null;
      }
    },
    onSubmit(buttonName) {
      var form = $(".form-function-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-SetCodeFunctionUpdate", { ...this.setCodeFunctionData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/SetCode/Function/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    createFunctionName() {
      var splittedWords = [];
      splittedWords = this.setCodeFunctionData.formulaName.split("_");
      var mergedString = "";
      splittedWords.forEach((element) => {
        var newString = "";
        var lowerString = element.toLowerCase();
        newString = lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
        mergedString += newString;
      });
      this.setCodeFunctionData.functionName = mergedString;
      return this.setCodeFunctionData.functionName;
    },
    selected(model, type) {
      this.selectedObj = {
        model: model,
        type: type,
        refresh: String.newGuid(),
      };
    },
    changed(model, type) {
      this.changedObj = { model: model, type: type, refresh: String.newGuid() };
    },
    modelChangeEmitter(setModelData, modelType) {
      if (modelType == 1) {
        this.setCodeFunctionData.functionOutputModel = {
          formulaName: setModelData.formulaName,
          name: setModelData.name,
          modelCode: setModelData.modelCode,
          description: setModelData.description,
        };
      } else {
        this.setCodeFunctionData.functionInputModel = {
          formulaName: setModelData.formulaName,
          name: setModelData.name,
          modelCode: setModelData.modelCode,
          description: setModelData.description,
        };
      }
      if (modelType == 1) {
        this.modelCode = setModelData.modelCode;
      } else {
        this.modelCode = setModelData.modelCode;
      }
    },
    onSelectedModel(rowData, modelType) {
      if (modelType == 1) {
        this.setCodeFunctionData.functionOutputModel = {
          formulaName: rowData.formulaName,
          name: rowData.name,
          publicId: rowData.publicId,
          modelCode: rowData.modelCode,
          description: rowData.description,
        };
        this.setCodeFunctionData.functionOutputModel.modelCamelCaseName =
          rowData.formulaName;
      } else {
        this.setCodeFunctionData.functionInputModel = {
          formulaName: rowData.formulaName,
          name: rowData.name,
          publicId: rowData.publicId,
          modelCode: rowData.modelCode,
          description: rowData.description,
        };
        this.setCodeFunctionData.functionInputModel.modelCamelCaseName =
          rowData.formulaName;
      }
    },
  },
  mounted() {
    this.getFunction();
  },
};
</script>
